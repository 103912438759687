import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api/products';
import { Container, NativeSlider, VariantChooser, useShopConfig, useProductDetailLink } from '@ttstr/components';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.gallery)], [
    product,
  ]);
  const thumbs = React.useMemo(
    () => [product.hero_image.thumb.url, ...product.gallery_image_urls.map((i) => i.thumb)],
    [product]
  );

  return (
    <article className="product-detail" itemScope itemType="http://schema.org/Product">
      <Helmet>
        <title>{product.title + (product.subtitle ? ` - ${product.subtitle}` : '')}</title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
      </Helmet>
      <meta itemProp="sku" content={String(product.id)} />

      <section className="product-content">
        <Container className="detail-page">
          <Row>
            <Col lg="6">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
                previewImage={product.image_thumb}
                thumbContainerClassName="random-rotation scribble-hover-overlay"
                thumbClassName="random-rotation-object"
              />
            </Col>
            <Col lg="6">
              <header className="text-center text-lg-left">
                {product.supertitle && <span className="text-muted">{product.supertitle}</span>}
                <h1 className="display-5 my-2 mt-0">
                  <span itemProp="name">{product.title}</span>
                  <small>{product.subtitle}</small>
                </h1>
              </header>

              <VariantChooser className="mb-4" product={product} />

              {product.description && (
                <>
                  <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
