import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article className="my-5">
      <Container className="list-page">
        <Helmet>
          <title>{t(`PRODUCTS.TITLE`)}</title>
        </Helmet>
        <img src={require('./assets/images/header2.jpg')} alt="Preorder" className="img-fluid mb-5" />
        {/* <h1 className="text-center mb-0 mt-5">{t(`PRODUCTS.TITLE`)}</h1> */}
        <Masonry
          id="product-listing"
          products={products}
          filters="off"
          columnClassName="col-6 col-md-6 col-lg-6 scribble-hover-overlay"
          itemClassName=""
          itemImageClassName="scribble-hover-overlay-object"
        />
        {loading && <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
